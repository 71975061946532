






















import Vue from 'vue';
import HealthTeamUtils, { HealthTeam } from '@/utils/HealthTeamUtils';
import { locales } from '@/i18n';

export default Vue.extend({
  computed: {
    table() {
      return {
        items: this.items,
        headers: [
          {
            text: this.$i18n.tc('healthteam.province'),
            value: 'province.name',
          },
          {
            text: this.$i18n.tc('healthteam.name_short'),
            value: 'name_short',
          },
          {
            text: '',
            value: 'actions',
          },
        ],
      };
    },

    locales() {
      return locales;
    },
  },

  data: (): {
    items?: HealthTeam[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await HealthTeamUtils.healthTeam.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: HealthTeam) {
      await this.$router.push({
        name: 'healthteam-edit',
        params: { id: item.id.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'healthteam-create',
      });
    },
  },
});
